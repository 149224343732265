@import "vars"
$animation: all .5s ease-in-out


.project-page
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center


  .category
    $sz: 1.5rem
    font-family: 'Futura PT', sans-serif
    transform: rotate(-90deg) translateY(calc(-36vw - #{$sz} - .5em))
    transform-origin: right top
    text-align: right
    position: absolute
    width: 100%
    font-size: $sz
    font-weight: 300
    white-space: nowrap

    &::after
      content: ' '
      display: inline-block
      height: .5rem
      width: 0
      margin-left: 0
      transform: rotate(45deg)
      background: $accent-color

      transition: $animation


  .banner
    position: relative
    height: 80vh
    width: 36vw

    img
      object-fit: cover
      height: 100%
      width: 100%
      filter: grayscale(1)
      transition: $animation

  .name
    margin-top: -14rem
    margin-bottom: 4rem
    z-index: $z-content
    position: relative

    font-family: 'Futura PT', sans-serif
    font-size: 10rem
    line-height: 10rem
    font-weight: 800
    text-align: center
    text-transform: uppercase
    color: transparent
    -webkit-text-stroke: 1px white
    text-decoration: none

    div
      transition: $animation
      transition-delay: .5s
      -webkit-text-stroke: 0 transparent
      color: transparent
      position: absolute
      top: 0

  &.-active
    .category::after
      width: .5rem
      margin-left: .5rem

    .name div
      top: 2rem
      color: white

    .banner img
      filter: grayscale(0)

  &.-left.-prepare
    transform: translate3d(-65%, 0, 0)

  &.-right.-prepare
    transform: translate3d(65%, 0, 0)